<template>
    <div class="container">
        <section class="content" :style="isShow?'height:450px':'height:150px;'">
            <div class="content-top">
                <div class="arrow">
                    <img @click="doShow" src="/imgs/shangla@2x.png" :style="isShow?'transform:rotate(180deg);':'transform:rotate(0);'"/>
                </div>
                <div class="MonitorplanNo">
                    <h4 >运输单号：{{list[0]?list[0].MonitorplanNo:''}}</h4>
                    <span class="type">{{list[0]?list[0].MonitorPlanType:''}}</span>
                </div>
                <div class="">
                    <img src="/imgs/chuangjianren@2x.png" alt="">
                    <span style="white-space: nowrap;">联系人：</span>
                    {{list[0]?list[0].PhoneNo:''}} {{list[0]?list[0].Creater:''}}
                </div>
                <div class="">
                    <img src="/imgs/shijian.png" alt="">
                    <span style="white-space: nowrap;">出发时间：</span>
                    {{list[0]?list[0].StartTime:''}}
                </div>
                <div>
                    <img src="/imgs/dizhi.png" alt="">
                    <span style="white-space: nowrap;">出发地：</span>
                    <div style="width:250px;">{{list[0]?list[0].StartPlace:''}}</div>
                </div>
            </div>
            <div v-if="isShow" class="content-bot">
                <div v-for="(item,idx) of list" :key="idx">
                    <div class="addr">
                        <div><span style="color:#888888;">{{item.Destination==item.StartPlace?'终点':'途径地'}}：</span><div class="palce">{{item.Destination}}</div></div>
                        <div style="color:#3591F0;"><span style="color:#888888;">状态：</span>{{item.Status==1?'运输中':item.Status==2?'已取消':item.IsChecked==1?'已交接':'已到达'}}</div>
                    </div>
                    <div class="list">
                        <div class="item" style="color:#B2B2B2;">
                            <div></div>
                            <div>运输设备</div>
                            <div>温度</div>
                            <div>物品清单</div>
                        </div>
                        <div class="item" v-for="(box,boxIdx) of item.boxlist" :key="boxIdx">
                            <div>{{boxIdx+1}}</div>
                            <div>{{box.boxName}}</div>
                            <div>{{box.temp?box.temp:'-'}}</div>
                            <div>{{box.listName?box.listName:'-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="test">
            <span>测试</span>
            <el-input-number v-model="num" controls-position="right" @change="handleChange" :min="0" :max="10" size="mini"></el-input-number>
        </div> -->
        <div id="map"></div> 
    </div>
</template>
<script>
/* eslint-disable */
import {
    getMonitorPlanFindByBoxNo,
    getFindByNoToChat,
    getJSON
} from "@/utils/api";
import AMap from 'AMap'
var WSCoordinate = require('../../utils/WSCoordinate')  
import axios from 'axios'

export default {
    name:'index',
    components:{},
    data () {
        return {
            isShow:false,
            openid:'',
            id:'',
            type:'',
            list:[],

            markers:[],
            polylines:[],
            trailerpolyList:[],
            
            num:0,

            islabel:false,
        }
    },
    methods:{   
        // 展示更多
        doShow(){
            this.isShow=!this.isShow
        },
        // 切换百度或高德
        handleChange(e){
            if(this.timer){
                clearInterval(this.timer)
                this.timer=null
            }
            this.getTestData()
        },
        // 测试数据
        getTestData(){
            const that = this
            this.map.clearMap()
            getJSON(this.num).then(res=>{
                let arr=[]
                const GpsList = res.data
                let timestamp_0=1478031031
                GpsList.map((item,idx)=>{
                    let item2=WSCoordinate.transformFromWGSToGCJ(parseFloat(item[1]),parseFloat(item[0]));
                    const x=parseFloat(item2.longitude).toFixed(6)
                    const y=parseFloat(item2.latitude).toFixed(6)
                    let t = item[4].substring(0,19);    
                    t = t.replace(/-/g,'/');
                    let timestamp = new Date(t).getTime();
                    timestamp = timestamp.toString().substr(0,10)
                    if(idx==0){
                        timestamp_0=Number(timestamp)
                    }
                    const item3={
                        "x": Number(x),
                        "y": Number(y),
                        "sp": parseInt(item[2]),
                        "ag": parseInt(item[3])?parseInt(item[3]):10,
                        "tm": idx==0?timestamp_0:Number(timestamp)-timestamp_0
                    }
                    arr.push(item3)
                })
                that.map.setZoom(11);
                // that.drawing(arr,'red')
                that.rectifying(arr)
            })
        },
        // 真实数据
        getData() {
            const that = this
            this.map.clearMap()
            this.islabel=false
            if(this.type=='device'){
                getMonitorPlanFindByBoxNo(this.openid,this.id).then(res=>{
                    const data=res.data
                    if (data.code == 1) {
                        const list = data.data
                        this.list=list
                        this.setMapData(list)
                    }else{
                        that.map.setZoom(3);
                    }
                }).catch(err=>{
                    console.log('err',err)
                })
            }else{
                getFindByNoToChat(this.openid,this.id).then(res=>{
                    const data=res.data
                    if (data.code == 1) {
                        const list = data.data
                        this.list=list
                        this.setMapData(list)
                    }else{
                        that.map.setZoom(3);
                    }
                }).catch(err=>{
                    console.log('err',err)
                })
            }  
        },
        setMapData(list){
            const that = this
            let GpsList=[]
            let longitude = ''
            let latitude = ''
            let markers=[]
            list.map((item, idx) => {
                let arr = item.BoxList.split("|")
                let boxlist = []
                let marker_location=WSCoordinate.transformFromBaiduToGCJ(item.Latitude,item.Longitude);
                
                const marker = new AMap.Marker({
                    position: new AMap.LngLat(marker_location.longitude, marker_location.latitude),
                    icon:new AMap.Icon({            
                        image: idx==0?'/imgs/loc_2.png':'/imgs/loc_1.png',
                        size: new AMap.Size(32, 32),  //图标所处区域大小
                        imageSize: new AMap.Size(32,32) //图标大小
                    }), 
                })

                marker.setLabel({
                    // offset: new AMap.Pixel(-5, 0),  //设置文本标注偏移量
                    content: `<div class="info ${'info-'+idx}"" title="${item.Destination}" style="${idx==0?'background:#3A83F8;':'background:#F73C3C;'}">${item.Destination}</div>`, //设置文本标注内容
                    direction: 'top', //设置文本标注方位
                    icon:'/imgs/address_red.png',
                });
                markers.push(marker)

                if(item.GpsList){
                    GpsList=item.GpsList
                    GpsList = GpsList.replace(/{/g,'[')
                    GpsList = GpsList.replace(/}/g,']')
                    GpsList =JSON.parse(GpsList)
                }

                if (item.BoxLongitude && item.BoxLatitude) {
                    let Box_location=WSCoordinate.transformFromWGSToGCJ(item.BoxLatitude,item.BoxLongitude);
                    longitude = Box_location.longitude
                    latitude = Box_location.latitude
                }

                arr.map(item2 => {
                    if (item2 != "") {
                        let arr2 = item2.split("+")
                        let param = {
                            boxName: arr2[0],
                            temp: arr2[2],
                            listName: arr2[1]
                        }
                        boxlist.push(param)
                    }
                })
                item.boxlist = boxlist
            })

            // 运输箱
            if(latitude&&longitude){
                const position = new AMap.LngLat(longitude,latitude);  // 标准写法
                that.map.setCenter(position);
                const carMarker = new AMap.Marker({
                    position: new AMap.LngLat(longitude,latitude),
                    icon:new AMap.Icon({            
                        image: '/imgs/loc_3.png',
                        size: new AMap.Size(32, 32),  //图标所处区域大小
                        imageSize: new AMap.Size(32,32) //图标大小
                    }), 
                });
                let box={
                    addr:'',
                    time:'',
                    temp:'',
                }
                AMap.plugin('AMap.Geocoder', function() {
                    var geocoder = new AMap.Geocoder({
                        extensions:'base'
                    })

                    geocoder.getAddress([longitude,latitude], function(status, result) {
                        if (status === 'complete' && result.info === 'OK') {
                            box.addr = result.regeocode.addressComponent.district + result.regeocode.addressComponent.street + result.regeocode.addressComponent.streetNumber
                            box.time = list[0].ArrivalTime
                            box.temp = ''
                        }
                    })
                })

                // // 点击事件
                carMarker.on('click',(e)=>{
                    if(that.islabel){
                        that.islabel=!that.islabel
                        carMarker.setLabel({
                            offset: new AMap.Pixel(-5, 0),  //设置文本标注偏移量
                            content: "", //设置文本标注内容
                            direction: 'top', //设置文本标注方位
                        });
                    }else{
                        that.islabel=!that.islabel
                        const content = `
                            <div style="color:#000;">
                                <div style="background:#fff;border:1px solid #3A83F8;padding:10px;border-radius:11px;">
                                    <div style="display:flex;align-items:center; margin-bottom:5px;">
                                        <img src="/imgs/wendu.png" alt="" style="width:15px;height:15px;">
                                        <span style="color:#888888">温度：</span>
                                        <span>${box.temp}</span>
                                    </div>
                                    <div style="display:flex;align-items:center; margin-bottom:5px;">
                                        <img src="/imgs/shijian.png" alt="" style="width:15px;height:15px;">
                                        <span style="color:#888888">更新时间：</span>
                                        <span>${box.time}</span>
                                    </div>
                                    <div style="display:flex;align-items:center;">
                                        <img src="/imgs/dizhi.png" alt="" style="width:15px;height:15px;">
                                        <span style="color:#888888">地址：</span>
                                        <span>${box.addr}</span>
                                    </div>
                                </div>
                                <div style="background:#fff;width:20px;height:20px;border-left:1px solid #3A83F8;border-bottom:1px solid #3A83F8;position:absolute;bottom:-7px;left:20px;transform: rotate(-45deg);"></div>
                            </div>
                        `
                        carMarker.setLabel({
                            offset: new AMap.Pixel(80, -10),  //设置文本标注偏移量
                            content: content, //设置文本标注内容
                            direction: 'top', //设置文本标注方位
                        });
                    }
                    
                })
                markers.push(carMarker)
            }else{
                if(list.length>0){
                    const start=WSCoordinate.transformFromBaiduToGCJ(list[0].StartLatitude,list[0].StartLongitude);
                    const position = new AMap.LngLat(start.longitude,start.latitude);  // 标准写法
                    that.map.setCenter(position);
                }
            }
            
            // 覆盖物
            for(let v of markers){
                that.map.add(v)
            }

            // 轨迹
            if(GpsList.length>0){
                let arr=[]
                let timestamp_0=1478031031
                GpsList.map((item,idx)=>{
                    let item2=WSCoordinate.transformFromWGSToGCJ(parseFloat(item[1]),parseFloat(item[0]));
                    let t = item[4].substring(0,19);    
                    t = t.replace(/-/g,'/'); //必须把日期'-'转为'/'
                    let timestamp = new Date(t).getTime();
                    timestamp = timestamp.toString().substr(0,10)
                    if(idx==0){
                        timestamp_0=Number(timestamp)
                    }
                    const item3={
                        "x": item2.longitude,
                        "y": item2.latitude,
                        "sp": parseInt(item[2]),
                        "ag": parseInt(item[3])?parseInt(item[3]):10,
                        "tm": idx==0?timestamp_0:Number(timestamp)-timestamp_0
                    }
                    arr.push(item3)
                })
                // that.drawing(arr,'red')
                that.rectifying(arr)
            }

            if (!that.timer) {
                that.timer = setInterval(() => {
                    that.getData()
                }, 30000)
            }
        },
        //纠偏
        rectifying(point_list){
            const that = this
            var grasp = new AMap.GraspRoad();
            grasp.driving(point_list,function(error,result){
                if(!error){
                    var newPath = result.data.points;//纠偏后的轨迹
                    that.drawing(newPath,'#3a83f8')
                }
            })
        },
        // 画线
        drawing(list,color){
            const that = this
            let arr=[]
            list.map(item=>{
                const item2=new AMap.LngLat(item.x,item.y)
                arr.push(item2)
            })
            // 创建折线实例
            var polyline = new AMap.Polyline({
                path: arr,  
                strokeWeight: 5, // 线条宽度，默认为 1
                strokeColor: color, // 线条颜色
                // lineJoin: 'round', // 折线拐点连接处样式
                showDir:true,
            });
            that.polylines.push(polyline)
            // 将折线添加至地图实例
            that.map.add(polyline);
            that.map.setFitView()
        },
    },
    computed:{

    },
    created(){
    },
    mounted () {
        this.map = new AMap.Map('map', {
            resizeEnable: true,
            zoom: 18
        })
        const params=this.$route.query
        this.openid=params.openid
        this.type=params.type
        this.id=params.id
        this.getData()
        
        if(!this.openid || !this.id || !this.type){
            this.$router.push('/404')
            // location.href="/404"
        }
    },
    beforeDestroy(){
        this.timer=null
        clearInterval(this.timer)
    },
    watch:{},
}
</script>
<style lang="less" scoped>
    .container{
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        #map{
            width:100vw; 
            height: 100vh; 
            position: absolute;
            top:0;
            left: 0;
            z-index: 1;
        } 
        .content{
            position: absolute;
            bottom:0;
            left: 0;
            z-index: 2;
            padding: 10px 20px;
            width: calc(100vw - 40px);
            background: #fff;
            box-shadow: 0 0 5px 0 grey;
            font-size: 14px;
            border-radius: 10px 10px 0px 0px;
            transition: all 0.3s ease;
            height: 150px;
            .content-top{
                .MonitorplanNo{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                }
                >div{
                    display: flex;
                    margin:5px 0;
                    span{
                        color: #888888;
                    }
                    .type{
                        color: #3A83F8;
                        font-weight: bold;
                    }
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
                .arrow{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto 10px;
                    width: 50px;
                    width: 50px;
                    height: 24px;
                    background: #FFFFFF;
                    border: 1px solid rgba(58, 131, 248, 0.15);
                    border-radius: 12px;
                    box-shadow: 0 5px 10px 0 #e0e0e0;
                    img{
                        width: 17px;
                        height: 17px;
                        transition: all 0.3s ease;
                        margin: 0;
                    }
                }
                
            }
            .content-bot{   
                overflow-y: auto;
                height: 300px;
                >div{
                    margin-bottom: 10px;
                }
                .addr{
                    background: rgba(#BFBFBF,0.3);
                    padding: 0 10px;
                    display: flex;
                    justify-content: space-between;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                    >div{
                        display: flex;
                        .palce{
                            width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .list{
                    margin:0 10px;
                    .item{
                        padding:5px 0;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid#E5E5E5;
                        &:last-child{
                            border-bottom:none;
                        }
                        >div{
                            font-size:12px;
                            padding: 0 5px;
                            width: calc(30% - 10px);
                            &:first-child{
                                width: 10%;
                            }
                        }
                    }
                }
            }
        }
        .test{
            background: #fff;
            border-radius: 5px;
            padding-left:10px;
            overflow: hidden;
            position: absolute;
            top:0;
            right: 120px;
            z-index: 2;
            box-shadow: 0 0 5px 0 grey;
            span{
                margin-right: 10px;
                font-size: 14px;
            }
        }
    }
</style>