<template>
    <div class="container">
        <!-- <el-radio-group class="radio" v-model="radio" size="mini">
            <el-radio-button label="高德"></el-radio-button>
            <el-radio-button label="百度"></el-radio-button>
        </el-radio-group> -->
        <div class="map">
            <GDmap v-if="radio=='高德'"></GDmap>
            <BDmap v-else></BDmap>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import GDmap from "@/views/mini/GDmap.vue"
import BDmap from "@/views/mini/BDmap.vue"

export default {
    name:'index',
    components:{
        GDmap,
        BDmap
    },
    data () {
        return {
            radio:"百度"
        }
    },
    methods:{
        doclick(){
            this.$router.push("/map")
        },
    },
    computed:{

    },
    created(){

    },
    mounted () {
        
    },
    beforeDestroy(){
        
    },
    watch:{},
}
</script>
<style lang="less" scoped>
    .radio{
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;
        box-shadow: 0 0 5px 0 grey;
    }
    .map{
        position: relative;
        z-index: 1;
    }
</style>